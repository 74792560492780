<template>
  <v-scale-transition
    mode="out-in"
  >
    <div
      v-if="isAuthenticated"
      class="light-theme pb-10"
    >
      <v-row
        no-gutters
        class="col-9 col-sm-12 col-lg-8 col-xl-5 mx-auto mt-n2
         mt-3 pa-0 flex-column justify-center"
      >
        <v-img
          v-if="paymentSuccess"
            class="align-self-center"
            title="успіх"
            src="@/assets/success.png"
            max-width="150px"
            alt="SUCCESS"
          />
        <v-img
          v-else
          class="align-self-center"
          title="помилка"
          src="@/assets/error.png"
          max-width="150px"
          alt="SUCCESS"
          />

        <v-subheader
          class="text--text height-auto text-h5 text-center d-block mb-1 text-uppercase mb-10">
          {{ paymentSuccess?
          'Оплата пройшла успішно. Платіж буде зараховано впродовж трьох діб.Дякуємо за платіж.':
            'помилка платежу' }}
        </v-subheader>
        <span class="align-self-center">
          Ви будете перенаправлені на головну сторінку через {{secondsToRedirect}} секунд
        </span>
        <div class=" divider_container">
        <v-divider
          class="cardDarkGray lighten-3 mb-5 mt-5"
        />
        </div>
        <span class="align-self-center">
          натисніть кнопку "На головну" щоб перейти негайно
        </span>

        <v-btn
          color="success"
          rounded
          class="butt mt-8"
          @click="onClickToHomeHandler"
        >
          на головну
        </v-btn>
      </v-row>
    </div>
  </v-scale-transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PaymentResult',
  title: 'Результат платежу',
  components: {
  },
  data() {
    return {
      secondsToRedirect: 15,
      paymentSuccess: true,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
  },
  beforeMount() {
    if (this.$route.query.success === 'true') {
      this.paymentSuccess = true;
    }
    if (this.$route.query.success === 'false') {
      this.paymentSuccess = false;
    }
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.secondsToRedirect > 1) {
        this.secondsToRedirect -= 1;
      } else {
        clearInterval(interval);
        this.$router.push({ path: '/' });
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    onClickToHomeHandler() {
      this.$router.push({ path: '/' });
    },
  },
};

</script>

<style lang="scss" scoped>
.v-list-item {
  min-height: auto !important;
}
.borderr{
  border: 2px solid red;
}
.butt{
  margin: 10px 0px 10px 0px;
  max-width: 90%;
  align-self: center;
}
.divider_container {
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
}
</style>
